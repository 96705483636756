var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"intro"}},[(_vm.$store.state.mainLogoToggle == true)?_c('div',{staticClass:"intro-container"},[_c('div',{staticClass:"intro-logo"},[_c('img',{style:({
          width: 'auto',
          height: '60vh',
          maxWidth: '75vw',
          maxHeight: '800px',
          position: 'absolute',
          left: '50%',
          top: '50%',
          transform: 'translate(-50%, -50%)',
        }),attrs:{"src":require('@/assets/logo-white.svg'),"alt":"레드핫 로고"}})])]):_vm._e(),_vm._m(0),_c('film-btn')],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"about"},[_c('div',{staticClass:"about-context"},[_c('ul',{staticClass:"about-title"},[_c('li',[_vm._v("REDHOT Think")]),_c('li',[_vm._v("REDHOT Work")]),_c('li',[_c('strong',[_vm._v("REDHOT CONTENTS")])])]),_c('p',[_vm._v(" 레드핫컨텐츠는 IMC Total Work 파트너입니다 "),_c('br'),_vm._v(" 디지털에 최적화된 IMC솔루션을 찾고 "),_c('br'),_vm._v(" 컨셉츄얼하고 임팩트 있는 크리에이티브를 제공합니다 ")]),_c('h2',[_vm._v("Planning - Creative - Media - Promotion")])])])}]

export { render, staticRenderFns }