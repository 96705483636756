<template>
    <div class="contents">
      <div class="contents-title">
        <hr><br>
        <h2>REDHOT</h2>
        <h1>WORKS</h1>
      </div>
      <div class="contents-container">
          <div class="contents-lists">
              <div class="work-el-wrapper"
              v-for="(item, i) in allContents" :key="i"
              :style="{backgroundImage: `url(${item.thumbnail})`}"
              @click="$router.push('/contents/' + item.uid )"
              >
                <div class="work-el-container">
                  <div class="work-info">
                      <h4>
                        {{item.title}}
                      </h4>
                      <p>
                        {{item.client}}
                      </p>
                  </div>
                </div>
              </div>
              <!-- <div class="more-btn-wrapper">
                <div class="more-btn-container">
                  <div class="more-btn">
                    <a href="http://redhotcontents.com/">FILM &gt;</a>
                  </div>
                </div>
              </div> -->
          </div>
      </div>
      <film-btn></film-btn>
    </div>
</template>

<script>
import FilmBtn from '../components/FilmBtn'
  export default {
    components :{
      FilmBtn
    },
  metaInfo(){
    return {
        titleTemplate: 'REDHOT | WORKS',
    }
  },
  data() {
    return {
      ref: this.$store.state.refContents,
    }
  },
  computed: {
    allContents(){ return this.$store.getters.allContents },
  },
  mounted() {
      this.$store.commit('loadAllContents')
  }
} 
</script>

<style scoped>
@media all and (min-width: 768px) {
  .contents {
    width: 100vw;
    height: inherit;
    padding: 0 0 21vh;
  }

  /* 타이틀 혹은 슬라이드 */
  .contents .contents-title {
    width: 50vw;
    margin: 12vh 0 4vh 3vw;
  }
  .contents .contents-title hr {
    float: left;
    width: 232px;
  }
  .contents .contents-title h2 {
    clear: both;
    color: #444;
    font-size: 3.8rem;
  }
  .contents .contents-title h1 {
    color: #000;
    font-size: 4rem;
  }
  .contents .contents-title p {
    margin: 32px 0 0;
    font-size: 1.7rem;
    color: #444;
  }

  /* 작품들 리스트 */
  .contents-container {
    width: 96vw;
    height: inherit;
    margin:0 auto;
  }
  .contents-lists {
    width: 96vw;
    margin: 0 auto;
  }
  /* 작품 하나 */
  .work-el-wrapper {
    width: 31.8vw;
    height: 16.875vw;
    margin: 0.1vw;
    background: #efefef;
    display: inline-block;
    background-size: cover;
  }
  .work-el-wrapper:hover .work-info {
    display: block;
    opacity: 1;
  }
  .work-el-container {
    width: 31.8vw;
    height: 16.875vw;
    display: table;
  }
  .work-info {
    width: 31.8vw;
    height: 16.875vw;
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    overflow: hidden;
    display: none;
    background: rgba(0, 0, 0, 0);
    opacity: 0;
  }
  .work-el:hover > .work-info, .work-info:hover {
    cursor: pointer;
    transition: 0.3s;
    background: rgba(0, 0, 0, 0.5);
  }
  .work-info h4, .work-info p {
    opacity: 1;
    color: #fff;
  }
  .work-info h4 {
    font-size: 1.7rem;
    margin: 0 0 16px;
    padding: 20% 0 0;
  }
  .work-info p {
    font-size: 1.3rem;
  }

  /* 마지막, 더보기 버튼 */
  .more-btn-wrapper {
    width: 95vw;
    margin: 12vh 0 0;
    text-align: end;
  }
  .more-btn-container {
    color: #000;
  }
  .more-btn {
    display: inline;
    cursor: pointer;
    font-size: 2rem;
    font-weight: 900;
    border-bottom: 4px solid #000;
  }
}
@media all and (max-width: 767px) {
  .contents {
    width: 100vw;
    height: inherit;
    padding: 0 0 21vh;
  }

  /* 타이틀 혹은 슬라이드 */
  .contents .contents-title {
    width: 50vw;
    margin: 104px 0 16px 6vw;
  }
  .contents .contents-title hr {
    float: left;
    width: 150px;
  }
  .contents .contents-title h2 {
    clear: both;
    color: #444;
    font-size: 2.4rem;
  }
  .contents .contents-title h1 {
    color: #000;
    font-size: 3rem;
  }
  /* 작품들 리스트 */
  .contents-container {
    width: 100vw;
    height: inherit;
  }
  .contents-lists {
    width: 86vw;
    margin: 0 auto;
  }
  /* 작품 하나 */
  .work-el-wrapper {
    width: 90vw;
    height: 50vw;
    margin: 2vw 1vw 1vw -2vw;
    display: inline-block;
    background-size: cover;
    background-repeat: no-repeat;
  }
  .work-el-container {
    width: 90vw;
    height: 50vw;
  }
  .work-info {
    width: 90vw;
    height: 35vw;
    padding: 15vw 0 0 0;
    text-align: center;
    overflow: hidden;
    background: rgba(0, 0, 0, 0.4);

  }
  .work-info h4, .work-info p {
    color: #fff;
  }
  .work-info h4 {
    font-size: 1.4rem;
    margin: 0 0 8px;
    padding: 5vw 0 0 0;
  }
  .work-info p {
    font-size: 0.9rem;
  }

  /* 마지막, 더보기 버튼 */
  .more-btn-wrapper {
    width: 86vw;
    margin: 8vh 0 0;
    text-align: end;
  }
  .more-btn-container {
    color: #000;
  }
  .more-btn {
    display: inline;
    cursor: pointer;
    font-size: 2rem;
    font-weight: 900;
    border-bottom: 4px solid #000;
  }
}

</style>
