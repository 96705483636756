<template>
  <div id="intro">
    <div class="intro-container" v-if="$store.state.mainLogoToggle == true">
      <div class="intro-logo">
        <!-- <video autoplay="autoplay" muted="muted" class="intro-video" playsinline>
                    <source :src="require('../assets/video/video_intro.mp4')" type="video/mp4">
                </video> -->
        <!-- <img class="logo-red" src="../assets/logo-red.svg" alt="" /> <br />
        <img class="logo-hot" src="../assets/logo-hot.svg" alt="" /> -->
        <img
          :src="require('@/assets/logo-white.svg')"
          alt="레드핫 로고"
          :style="{
            width: 'auto',
            height: '60vh',
            maxWidth: '75vw',
            maxHeight: '800px',
            position: 'absolute',
            left: '50%',
            top: '50%',
            transform: 'translate(-50%, -50%)',
          }"
        />
      </div>
    </div>
    <div class="about">
      <div class="about-context">
        <ul class="about-title">
          <li>REDHOT Think</li>
          <li>REDHOT Work</li>
          <li><strong>REDHOT CONTENTS</strong></li>
        </ul>
        <p>
          레드핫컨텐츠는 IMC Total Work 파트너입니다 <br />
          디지털에 최적화된 IMC솔루션을 찾고 <br />
          컨셉츄얼하고 임팩트 있는 크리에이티브를 제공합니다
        </p>
        <h2>Planning - Creative - Media - Promotion</h2>
      </div>
    </div>
    <film-btn></film-btn>
  </div>
</template>

<script>
import FilmBtn from "../components/FilmBtn";
export default {
  components: {
    FilmBtn,
  },
  data() {
    return {
      path: this.$route.path,
    };
  },
  watch: {
    path(e) {
      console.log(e);
    },
  },
  mounted() {
    // this.$store.commit('logoAnime', '.logo-red')
    // this.$store.commit('logoAnime2', '.logo-hot')
  },
};
</script>

<style scoped>
@media all and (min-width: 1641px) {
  #intro {
    width: 100vw;
    background: #ea3022;
  }
  .intro-logo {
    width: 100vw;
    /* overflow: hidden; */
    box-shadow: 0 0 0 10px #ea3022 inset;
    background: #ea3022;
  }
  .intro-video {
    width: 100%;
    height: auto;
    align-items: center;
    background: #ea3022;
    margin: -40px auto 0;
  }

  /* 로고 애니메이션 */
  .intro-container {
    width: 100vw;
    height: 100vh;
  }
  .intro-logo > img {
    position: relative;
    width: 32rem;
    height: auto;
  }
  .intro-logo .logo-red {
    top: 13rem;
    left: 46%;
    transform: translateX(-50%);
  }
  .intro-logo .logo-hot {
    top: 0;
    left: 55%;
    transform: translateX(-50%);
  }

  /* about */
  .about {
    width: 100vw;
    height: 60vh;
    padding: 20vh 0;
  }
  .about-context {
    width: 42vw;
    margin: 0 0 0 4vw;
    color: #fff;
    word-break: keep-all;
  }
  .about-context .about-title {
    font-size: 3.7rem;
    line-height: 3.3rem;
    font-weight: 300 !important;
  }
  .about-context .about-title strong {
    font-weight: 700;
  }
  .about-context p {
    font-size: 1rem;
    line-height: 1.5rem;
    margin: 16px 0 8px;
  }
}

@media all and (min-width: 1441px) and (max-width: 1640px) {
  #intro {
    width: 100vw;
    background: #ea3022;
  }
  .intro-logo {
    width: 100vw;
    /* overflow: hidden; */
    box-shadow: 0 0 0 10px #ea3022 inset;
    background: #ea3022;
  }
  .intro-video {
    width: 100%;
    height: auto;
    align-items: center;
    background: #ea3022;
    margin: -40px auto 0;
  }

  /* 로고 애니메이션 */
  .intro-container {
    width: 100vw;
    height: 100vh;
  }
  .intro-logo > img {
    position: relative;
    width: 30rem;
    height: auto;
  }
  .intro-logo .logo-red {
    top: 14rem;
    left: 45%;
    transform: translateX(-50%);
  }
  .intro-logo .logo-hot {
    top: 2rem;
    left: 55%;
    transform: translateX(-50%);
  }

  /* about */
  .about {
    width: 100vw;
    height: 60vh;
    padding: 20vh 0;
  }
  .about-context {
    width: 42vw;
    margin: 0 0 0 4vw;
    color: #fff;
    word-break: keep-all;
  }
  .about-context .about-title {
    font-size: 3.7rem;
    line-height: 3.3rem;
    font-weight: 300 !important;
  }
  .about-context .about-title strong {
    font-weight: 700;
  }
  .about-context p {
    font-size: 1rem;
    line-height: 1.5rem;
    margin: 16px 0 8px;
  }
}

@media all and (min-width: 1281px) and (max-width: 1440px) {
  #intro {
    width: 100vw;
    background: #ea3022;
  }
  .intro-logo {
    width: 100vw;
    /* overflow: hidden; */
    box-shadow: 0 0 0 10px #ea3022 inset;
    background: #ea3022;
  }
  .intro-video {
    width: 100%;
    height: auto;
    align-items: center;
    background: #ea3022;
    margin: -40px auto 0;
  }

  /* 로고 애니메이션 */
  .intro-container {
    width: 100vw;
    height: 100vh;
  }
  .intro-logo > img {
    position: relative;
    width: 28rem;
    height: auto;
  }
  .intro-logo .logo-red {
    top: 14rem;
    left: 44%;
    transform: translateX(-50%);
  }
  .intro-logo .logo-hot {
    top: 3rem;
    left: 55%;
    transform: translateX(-50%);
  }

  /* about */
  .about {
    width: 100vw;
    height: 60vh;
    padding: 20vh 0;
  }
  .about-context {
    width: 42vw;
    margin: 0 0 0 4vw;
    color: #fff;
    word-break: keep-all;
  }
  .about-context .about-title {
    font-size: 3.7rem;
    line-height: 3.3rem;
    font-weight: 300 !important;
  }
  .about-context .about-title strong {
    font-weight: 700;
  }
  .about-context p {
    font-size: 1rem;
    line-height: 1.5rem;
    margin: 16px 0 8px;
  }
}

@media all and (min-width: 961px) and (max-width: 1280px) {
  #intro {
    width: 100vw;
    background: #ea3022;
  }
  .intro-logo {
    width: 100vw;
    /* overflow: hidden; */
    box-shadow: 0 0 0 10px #ea3022 inset;
    background: #ea3022;
  }
  .intro-video {
    width: 100%;
    height: auto;
    align-items: center;
    background: #ea3022;
    margin: -40px auto 0;
  }

  /* 로고 애니메이션 */
  .intro-container {
    width: 100vw;
    height: 100vh;
  }
  .intro-logo > img {
    position: relative;
    width: 30rem;
    height: auto;
  }
  .intro-logo .logo-red {
    top: 12rem;
    left: 42%;
    transform: translateX(-50%);
  }
  .intro-logo .logo-hot {
    top: 0rem;
    left: 56%;
    transform: translateX(-50%);
  }

  /* about */
  .about {
    width: 100vw;
    height: 60vh;
    padding: 20vh 0;
  }
  .about-context {
    width: 42vw;
    margin: 0 0 0 4vw;
    color: #fff;
    word-break: keep-all;
  }
  .about-context .about-title {
    font-size: 3.7rem;
    line-height: 3.3rem;
    font-weight: 300 !important;
  }
  .about-context .about-title strong {
    font-weight: 700;
  }
  .about-context p {
    font-size: 1rem;
    line-height: 1.5rem;
    margin: 16px 0 8px;
  }
}

@media all and (min-width: 768px) and (max-width: 960px) {
  #intro {
    width: 100vw;
    background: #ea3022;
  }
  .intro-logo {
    width: 100vw;
    /* overflow: hidden; */
    box-shadow: 0 0 0 10px #ea3022 inset;
    background: #ea3022;
  }
  .intro-video {
    width: 100%;
    height: auto;
    align-items: center;
    background: #ea3022;
    margin: -40px auto 0;
  }

  /* 로고 애니메이션 */
  .intro-container {
    width: 100vw;
    height: 100vh;
  }
  .intro-logo > img {
    position: relative;
    width: 28rem;
    height: auto;
  }
  .intro-logo .logo-red {
    top: 14rem;
    left: 41%;
    transform: translateX(-50%);
  }
  .intro-logo .logo-hot {
    top: 3rem;
    left: 58%;
    transform: translateX(-50%);
  }

  /* about */
  .about {
    width: 100vw;
    height: 60vh;
    padding: 20vh 0;
  }
  .about-context {
    width: 42vw;
    margin: 0 0 0 4vw;
    color: #fff;
    word-break: keep-all;
  }
  .about-context .about-title {
    font-size: 3.7rem;
    line-height: 3.3rem;
    font-weight: 300 !important;
  }
  .about-context .about-title strong {
    font-weight: 700;
  }
  .about-context p {
    font-size: 1rem;
    line-height: 1.5rem;
    margin: 16px 0 8px;
  }
}

@media all and (min-width: 416px) and (max-width: 767px) {
  #intro {
    width: 100vw;
    background: #ea3022;
  }
  .intro-logo {
    width: 100vw;
    /* overflow: hidden; */
    background: #ea3022;
    box-shadow: 0 0 0 10px #ea3022 inset;
  }
  .intro-video {
    width: 200%;
    height: auto;
    margin: 25% 0 0 -50%;
    box-shadow: 0 0 0 10px #ea3022 inset;
  }

  /* 로고 애니메이션 */
  .intro-container {
    width: 100vw;
    height: 812px;
  }
  .intro-logo > img {
    position: relative;
    width: 16rem;
  }
  .intro-logo .logo-red {
    top: 18rem;
    left: 43%;
    transform: translateX(-50%);
  }
  .intro-logo .logo-hot {
    top: 12rem;
    left: 55%;
    transform: translateX(-50%);
  }

  /* about */
  .about {
    width: 100vw;
    height: 480px;
    padding: 340px 0 0;
  }
  .about-context {
    width: 92vw;
    margin: 0 0 0 4vw;
    color: #fff;
    word-break: keep-all;
  }
  .about-context .about-title {
    font-size: 2rem;
    line-height: 2.1rem;
    font-weight: 300 !important;
  }
  .about-context .about-title strong {
    font-weight: 700;
  }
  .about-context p {
    font-size: 0.9rem;
    line-height: 1.4rem;
    margin: 12px 0 8px;
  }
}

@media all and (min-width: 376px) and (max-width: 415px) {
  #intro {
    width: 100vw;
    background: #ea3022;
  }
  .intro-logo {
    width: 100vw;
    /* overflow: hidden; */
    background: #ea3022;
    box-shadow: 0 0 0 10px #ea3022 inset;
  }
  .intro-video {
    width: 200%;
    height: auto;
    margin: 25% 0 0 -50%;
    box-shadow: 0 0 0 10px #ea3022 inset;
  }

  /* 로고 애니메이션 */
  .intro-container {
    width: 100vw;
    height: 812px;
  }
  .intro-logo > img {
    position: relative;
    width: 50vw;
  }
  .intro-logo .logo-red {
    top: 12rem;
    left: 40%;
    transform: translateX(-50%);
  }
  .intro-logo .logo-hot {
    top: 7rem;
    left: 57%;
    transform: translateX(-50%);
  }

  /* about */
  .about {
    width: 100vw;
    height: 480px;
    padding: 340px 0 0;
  }
  .about-context {
    width: 92vw;
    margin: 0 0 0 4vw;
    color: #fff;
    word-break: keep-all;
  }
  .about-context .about-title {
    font-size: 2rem;
    line-height: 2.1rem;
    font-weight: 300 !important;
  }
  .about-context .about-title strong {
    font-weight: 700;
  }
  .about-context p {
    font-size: 0.9rem;
    line-height: 1.4rem;
    margin: 12px 0 8px;
  }
}

@media all and (min-width: 361px) and (max-width: 375px) {
  #intro {
    width: 100vw;
    background: #ea3022;
  }
  .intro-logo {
    width: 100vw;
    /* overflow: hidden; */
    background: #ea3022;
    box-shadow: 0 0 0 10px #ea3022 inset;
  }
  .intro-video {
    width: 200%;
    height: auto;
    margin: 25% 0 0 -50%;
    box-shadow: 0 0 0 10px #ea3022 inset;
  }

  /* 로고 애니메이션 */
  .intro-container {
    width: 100vw;
    height: 812px;
  }
  .intro-logo > img {
    position: relative;
    width: 50vw;
  }
  .intro-logo .logo-red {
    top: 15rem;
    left: 40%;
    transform: translateX(-50%);
  }
  .intro-logo .logo-hot {
    top: 10rem;
    left: 58%;
    transform: translateX(-50%);
  }

  /* about */
  .about {
    width: 100vw;
    height: 480px;
    padding: 340px 0 0;
  }
  .about-context {
    width: 92vw;
    margin: 0 0 0 4vw;
    color: #fff;
    word-break: keep-all;
  }
  .about-context .about-title {
    font-size: 2rem;
    line-height: 2.1rem;
    font-weight: 300 !important;
  }
  .about-context .about-title strong {
    font-weight: 700;
  }
  .about-context p {
    font-size: 0.9rem;
    line-height: 1.4rem;
    margin: 12px 0 8px;
  }
}

@media all and (min-width: 321px) and (max-width: 360px) {
  #intro {
    width: 100vw;
    background: #ea3022;
  }
  .intro-logo {
    width: 100vw;
    /* overflow: hidden; */
    background: #ea3022;
    box-shadow: 0 0 0 10px #ea3022 inset;
  }
  .intro-video {
    width: 200%;
    height: auto;
    margin: 25% 0 0 -50%;
    box-shadow: 0 0 0 10px #ea3022 inset;
  }

  /* 로고 애니메이션 */
  .intro-container {
    width: 100vw;
    height: 812px;
  }
  .intro-logo > img {
    position: relative;
    width: 50vw;
  }
  .intro-logo .logo-red {
    top: 14rem;
    left: 40%;
    transform: translateX(-50%);
  }
  .intro-logo .logo-hot {
    top: 10rem;
    left: 58%;
    transform: translateX(-50%);
  }

  /* about */
  .about {
    width: 100vw;
    height: 480px;
    padding: 340px 0 0;
  }
  .about-context {
    width: 92vw;
    margin: 0 0 0 4vw;
    color: #fff;
    word-break: keep-all;
  }
  .about-context .about-title {
    font-size: 2rem;
    line-height: 2.1rem;
    font-weight: 300 !important;
  }
  .about-context .about-title strong {
    font-weight: 700;
  }
  .about-context p {
    font-size: 0.9rem;
    line-height: 1.4rem;
    margin: 12px 0 8px;
  }
}

@media all and (max-width: 320px) {
  #intro {
    width: 100vw;
    background: #ea3022;
  }
  .intro-logo {
    width: 100vw;
    /* overflow: hidden; */
    background: #ea3022;
    box-shadow: 0 0 0 10px #ea3022 inset;
  }
  .intro-video {
    width: 200%;
    height: auto;
    margin: 25% 0 0 -50%;
    box-shadow: 0 0 0 10px #ea3022 inset;
  }

  /* 로고 애니메이션 */
  .intro-container {
    width: 100vw;
    height: 812px;
  }
  .intro-logo > img {
    position: relative;
    width: 50vw;
  }
  .intro-logo .logo-red {
    top: 8rem;
    left: 40%;
    transform: translateX(-50%);
  }
  .intro-logo .logo-hot {
    top: 4rem;
    left: 58%;
    transform: translateX(-50%);
  }

  /* about */
  .about {
    width: 100vw;
    height: 340px;
    padding: 88px 0;
  }
  .about-context {
    width: 92vw;
    margin: 0 0 0 4vw;
    color: #fff;
    word-break: keep-all;
  }
  .about-context .about-title {
    font-size: 2rem;
    line-height: 2.1rem;
    font-weight: 300 !important;
  }
  .about-context .about-title strong {
    font-weight: 700;
  }
  .about-context p {
    font-size: 0.9rem;
    line-height: 1.4rem;
    margin: 12px 0 8px;
  }
}
</style>
